<template>
  <div class="container">
    <Tabs value="name1">
      <TabPane label="员工" name="name1">
        <div style="min-height: 300px">
          <Row>
            <Col span="24">
              <Form :label-width="80">
                <Row>
                  <Col span="12">
                    <FormItem label="自选时间">
                      <DatePicker type="daterange" v-model="time" placeholder="请选择月份" style="width: 200px"></DatePicker>
                    </FormItem>
                  </Col>
                  <Col span="12">
                    <FormItem :label-width="0">
                      <Row>
                        <Col span="12">
                          <Button type="primary" @click="search()">搜索</Button>
                          <Button style="margin-left: 8px" @click="reset()">重置</Button>
                        </Col>
                        <Col span="12">
                          <Button type="primary" icon="ios-browsers-outline">切换</Button>
                          <Button type="warning" style="margin-left: 8px" @click="exportData">导出</Button>
                        </Col>
                      </Row>
                    </FormItem>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col span="24">
              <Table border :columns="columns" :data="list" ref="table">
                <template slot="cont" slot-scope="{row}">
            <span v-for="(item,index) in row.list" :key="item.id">
              <Tag :color="colors[index]">
                <span>礼品：{{ item.title }}</span>
                <label style="font-weight: bold">&emsp;数量：{{ item.value }}</label>
              </Tag>
            </span>
                </template>
              </Table>
            </Col>
          </Row>
        </div>
      </TabPane>
      <TabPane label="礼品" name="name2">
        <div style="min-height: 300px">
          <Row>
            <Col span="24">
              <Form :label-width="80">
                <Row>
                  <Col span="12">
                    <FormItem label="自选时间">
                      <DatePicker type="daterange" v-model="times" placeholder="请选择月份" style="width: 200px"></DatePicker>
                    </FormItem>
                  </Col>
                  <Col span="12">
                    <FormItem :label-width="0">
                      <Row>
                        <Col span="12">
                          <Button type="primary" @click="searchs()">搜索</Button>
                          <Button style="margin-left: 8px" @click="resets()">重置</Button>
                        </Col>
                        <Col span="12">
                          <Button type="warning" style="margin-left: 8px" @click="exportData">导出</Button>
                        </Col>
                      </Row>
                    </FormItem>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col span="24">
              <Table border :columns="columnsd" :data="lists" ref="table">
                <template slot="cont" slot-scope="{row}">
                  <span v-for="(item,index) in row.list" :key="item.id">
                    <Tag :color="colors[index]">{{ item.name }}</Tag>
                  </span>
                </template>
              </Table>
            </Col>
          </Row>
        </div>
      </TabPane>
    </Tabs>
  </div>
</template>
<script>
import Vue from 'vue'
import axios from "axios";
import {Button, Table, Row, Col, Form, FormItem, DatePicker, Tag, Tabs, TabPane} from 'view-design';
import 'view-design/dist/styles/iview.css';

Vue.component('Button', Button);
Vue.component('Table', Table);
Vue.component('Row', Row);
Vue.component('Col', Col);
Vue.component('Form', Form);
Vue.component('FormItem', FormItem);
Vue.component('DatePicker', DatePicker);
Vue.component('Tag', Tag);
Vue.component('Tabs', Tabs);
Vue.component('TabPane', TabPane);
export default {
  name: "Census",
  data() {
    return {
      time: '',
      times: '',
      list: [],
      lists: [],
      colors: ['primary', 'success', 'error', 'warning', 'magenta', 'red', 'volcano', 'orange', 'gold', 'yellow', 'lime'],
      columns: [
        {
          "title": "姓名",
          "key": "name",
          "width": 120
        },
        {
          "title": "电话",
          "key": "mobile",
          "width": 130
        },
        {
          "title": "所选礼物",
          "slot": 'cont',
        }
      ],
      columnsd: [
        {
          "title": "礼物名称",
          "key": "title",
        },
        {
          "title": "所选数量",
          "key": "value",
        },
        {
          "title": "选择人员",
          "slot": "cont",
        }
      ],
      height: 0
    }
  },
  created() {

  },
  mounted() {
    this.search()
    this.searchs()
  },
  methods: {
    // 以人为单位
    search() {
      const params = {}
      params.time = this.time
      axios.get('http://resapi.121314.com/sidebar/gift/userRecord', {params})
          .then((res) => {
            const result = res.data
            if (result.code === 200) {
              this.list = result.result
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    // 以礼物为单位
    searchs() {
      const params = {}
      params.time = this.times
      axios.get('http://resapi.121314.com/sidebar/gift/record', {params})
          .then((res) => {
            const result = res.data
            if (result.code === 200) {
              this.lists = result.result
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    // 查询礼物已选数量
    searchGift() {
      const params = {}
      params.time = this.times
      axios.get('http://resapi.121314.com/sidebar/gift/userIndex', {params})
          .then((res) => {
            const result = res.data
            if (result.code === 200) {
              this.giftList = result.result
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    reset() {
      this.time = ''
      this.search()
    },
    resets() {
      this.times = ''
      this.searchs()
    },
    resetGift() {
      this.times = ''
      this.searchGift()
    },
    exportData() {
      this.$refs.table.exportCsv({
        filename: '工龄礼自选礼物统计'
      });
    }
  }
}
</script>

<style scoped>
.container {
  padding: 20px;
}
</style>