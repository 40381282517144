import { render, staticRenderFns } from "./Census.vue?vue&type=template&id=0553b3b3&scoped=true"
import script from "./Census.vue?vue&type=script&lang=js"
export * from "./Census.vue?vue&type=script&lang=js"
import style0 from "./Census.vue?vue&type=style&index=0&id=0553b3b3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0553b3b3",
  null
  
)

export default component.exports